function isUserLogged() {
    return getAuthUser()?.value?.uuid ? true : false
}

export function goToDonatella() {
    const router = useRouter()
    const { $eventBus } = useNuxtApp()

    if (isUserLogged()) {
        router.push('/donatella')
    } else {
        $eventBus.$emit('showDonatellaDialog')
    }
}